<template>
  <div class="d-flex flex-column flex-root">
    <div style="background-color: #eee !important;"
        class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
        id="kt_login"
    >
      <!--begin::Aside-->

      <!--begin::Aside-->

      <!--begin::Content-->
      <div
          class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
      >
        <div class="d-flex flex-column-fluid flex-center mt-lg-0">
          <router-view></router-view>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>
<style scoped>
.login-title {
  position: relative;
  top: -136px;
  text-align: center;
}

.cls-1, .cls-6 {
  fill: #efefef;
  stroke-width: 5px;
}

.cls-1, .cls-5, .cls-6 {
  stroke-miterlimit: 10;
}

.cls-1 {
  stroke: url(#linear-gradient);
}

.cls-2 {
  font-size: 91.41px;
  fill: #111;
  font-family: Poppins-Regular, Poppins;
}

.cls-3 {
  font-size: 85.42px;
  font-family: Comfortaa-Bold, Comfortaa;
  font-weight: 700;
}

.cls-4 {
  letter-spacing: -0.03em;
}

.cls-5 {
  fill: none;
  stroke: #000;
  stroke-width: 4px;
}

.cls-6 {
  stroke: url(#linear-gradient-2);
}

.cls-7 {
  opacity: 0.5;
}
</style>
<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "auth",
  mounted() {
    // check if current user is authenticated
    if (this.checkAuth()) {
      this.$router.push({name: "survey"});
    }
  },
  methods: {},
  computed: {
    ...mapGetters([
      "isAuthenticated"
    ]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-5.jpg";
    },
    backgroundImageRight() {
      return process.env.BASE_URL + "media/bg/login_bg_right.jpg";
    }
  }
};
</script>
